import { PageProps } from "gatsby";
import Layout, { MainContainerDiv, PageTitle } from "../components/Layout";
import React from "react";

export default (props: PageProps) => {
	return (
		<Layout title="Contact">
			<MainContainerDiv>
				<PageTitle>Contact</PageTitle>
				<p>
					For bookings, press and general enquiries, email us: <a href="mailto:management@flynneffectmusic.com">management@flynneffectmusic.com</a><br />
				For anything else, please head to <a href="https://facebook.com/FlynnEffectMusic">our Facebook page</a>. We'd love to hear from you.
			</p>
			</MainContainerDiv>
		</Layout>
	);
};